<template>
  <!--begin::Dashboard-->
  <div class="p-5 container">
    <form action="">
      <div class="label mb-5">
        <label for="">หัวข้อกระทู้</label>
        <input type="text" class="form-control" v-model="title"/>
      </div>
      <div style="position: relative;">
        <label for="">รายละเอียด</label>
        <div class="preview">
          <div v-for="(image, index) in imagesPreview" :key="index">
            <img v-if="image.preview" :src="image.preview" />
          </div>
        </div>
        <div style="position: absolute;bottom: 0;bottom: 2vh;right: 3vh;">
          <button
            type="button"
            @click="clickInputFile()"
            class="btn btn-light-primary mx-2"
          >
            <i class="las la-image"></i>
          </button>
          <button
            type="button"
            class="btn btn-primary mx-2"
            @click="addEditWebboard"
          >
            <i class="las la-paper-plane"></i>
          </button>
        </div>
        <textarea
          v-model="description"
          class="form-control"
          id=""
          cols="30"
          rows="5"
        ></textarea>
      </div>
      <p class="text-danger">กรุณาระบุ</p>
      <input
        type="file"
        id="imagPath"
        @change="onFileChange($event)"
        multiple
        accept="image/*"
        hidden
      />
    </form>
    <div class="row"></div>
  </div>
  <div id="modal" class="modal" onclick="this.style.display='none'">
    <span class="close">&times;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <div class="modal-content">
      <img id="img" style="max-width:100%" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { useRoute } from "vue-router";
const listData = ref();
const listComment = ref();
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import router from "@/router";
export default defineComponent({
  name: "blog-detail",
  components: {},
  data() {
    return {
      imageUrl: process.env.VUE_APP_API_URL,
      title: "",
      description: "",
      dataId: this.$route.params.id,
      images: [
        {
          files: "",
        },
      ],
      imagesPreview: [
        {
          preview: "",
        },
      ],
    };
  },
  setup() {
    const route = useRoute();
    onMounted(() => {
      setCurrentPageBreadcrumbs( "สร้างกระทู้", ["ถามตอบ"]);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataList();
    });
    const getDataList = () => {
     
      if (route.params.id) {
        axios
          .get(
            process.env.VUE_APP_API_URL +
              "/getWebboard?id=" +
              route.params.id ,
            {
              headers: {
                token: localStorage.getItem("id_token")
              },
            }
          )
          .then((response) => {
            // console.log(response.data.result);
            listComment.value = response.data.data;
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    };

    return {
      getDataList,
      listData,
      listComment,
      store,
      router,
    };
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/avatars/blank.png";
    },
    async addEditWebboard() {
      Swal.fire({
        title: "Loading",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      await store.dispatch(Actions.VERIFY_AUTH);
      const [errorName] = Object.keys(store.getters.getErrors);
      const error = store.getters.getErrors[errorName];
      if (!error) {
        if (this.description) {

          const formData = new FormData();
          if (this.dataId) {
              formData.append("id", this.dataId+"");
          }
          formData.append("title", this.title);
          formData.append("description", this.description);
          if (this.images.length > 0) {
            for (const loopfile of this.images) {
              formData.append("picturesPath", loopfile.files);
            }
          }
          formData.append("category", "webboard");
          formData.append("enable", "true");
          axios
            .post(
              process.env.VUE_APP_API_URL +
                "/addEditWebboard",
              formData,
              {
                headers: {
                  token: localStorage.getItem("id_token")
                },
              }
            )
            .then((res) => {
              Swal.fire({
                title: "บันทึกรายการสำเร็จ",
                text: "รายการข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "ตกลง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-primary",
                },
              }).then(function() {
                router.go(0);
              });
            })
            .catch((error) => {
              Swal.fire({
                title: "แจ้งเตือนข้อผิดพลาด",
                text: error.response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            });
        }
      } else {
        Swal.close()
        router.push({ name: "sign-in" });
      }
    },
    clickInputFile() {
      const imagPath: any = document.getElementById("imagPath");
      imagPath.click();
    },
    onFileChange(e) {
      this.imagesPreview = [];
      this.images = [];
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        this.images.push({
          files: files[i],
        });
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target) {
            const data: any = event.target.result;
            this.imagesPreview.push({ preview: data });
          }
        };
        reader.readAsDataURL(files[i]);
      }
    },
    onClick(element) {
      // console.log(element);
      const img: any = document.getElementById("img");
      const modal: any = document.getElementById("modal");
      img.src = element.target.currentSrc;
      modal.style.display = "block";
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
  },
});
</script>

<style scoped>
.preview {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
}

.preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  margin: 5px;
}
</style>